import React from "react"
import Constraint from "../layout/wrapper"
import WideWrapper from "../layout/wide-wrapper"

const db = [
  {
    q: "About Orange and Crane and Ciergio",
    a: (
      <>
        <h4>​​Who is Orange and Crane Innovations Inc. (OCI)?</h4>
        <p>
          Orange and Crane Innovations Inc. (OCI) is a specialty IT Development
          Company that develops Church and Community Platforms called Ciergio.
          OCI believes in creating purposeful technology to keep community
          leaders and members DIGITALLY ENGAGED AND CONNECTED.
        </p>
        <h4>What is Ciergio?</h4>
        <p>
          Ciergio is a comprehensive cloud-based platform catered to churches
          and religious communities.
        </p>
      </>
    ),
  },

  {
    q: "About the App",
    db: [
      {
        q: "My Account",
        a: (
          <>
            <h4>How do I create an Account for the app?</h4>
            <p>
              You can create an account with your Facebook account, e-mail
              address, or Apple ID for iOS models.
            </p>
            <h4>Do I need to create an Account to use the app?</h4>
            <p>
              No you don’t need to create an account to use the app. Click on
              “Skip this process” to access the app.
              <br />
              <br />
              If you do not have an account, you will NOT be able to send a
              “Prayer Request”, a “Private Message”, or receive “Notification”
              from the app.
            </p>
            <h4>How do I retrieve my password via email?</h4>
            <p>
              To retrieve your password, click ‘Forget Password” and wait for an
              email with your reset code. Follow the instructions on the email
              to create your new password.
            </p>
          </>
        ),
      },
      {
        q: "Content",
        a: (
          <>
            <h4>
              Can I share the bulletin to my Facebook, Twitter, and other social
              media accounts?
            </h4>
            <p>
              Once you are registered into the app, you can share the bulletin
              to your Facebook account and other social media platform by
              pressing the “Share Button”. You may also copy and paste the link
              onto other social media platforms.
            </p>
          </>
        ),
      },
      {
        q: "Security",
        a: (
          <>
            <h4>Is my data secured?</h4>
            <p>
              We value the importance of security and privacy of your data so
              yes, it is secured. All data is encrypted and backed up hourly,
              while community activities are tracked. We also continuously
              upgrade our services to make sure we are using the latest
              technology to keep your data protected.
            </p>
          </>
        ),
      },
      {
        q: "Troubleshooting",
        a: (
          <>
            <h4>How can I download the app to my phone?</h4>
            <p>
              For Android users, go to Google Play Store and search for the (MTQ
              or UP Parish) App
              <br />
              <br />
              For Apple users, go to App Store and search for the (MTQ or UP
              Parish) App
            </p>
            <h4>Can the app be downloaded on Android Or Google TV?</h4>
            <p>Why can’t I download the app?</p>
            <h4>Why can’t I download the app?</h4>
            <p>
              There are mainly 2 reasons why you may have trouble downloading
              the app due to: 1) Poor internet connection or 2) The phone model
              you may be using is not compatible with the app.
              <br />
              <br />
              Make sure your internet connection is stable by connecting to a
              strong Wi-Fi network or use reliable mobile data. Once your
              connection is stable, try and download the app again.
            </p>
            <h4>What iOS Models will prevent me from downloading the app?</h4>
            <p>
              The following iOS models are not compatible with the app:
              <ul>
                <li>iPhone OS 1</li>
                <li>iPhone OS 2</li>
                <li>iPhone OS 3</li>
                <li>iOS 4</li>
                <li>iOS 5</li>
                <li>iOS 6</li>
              </ul>
            </p>
            <h4>
              What Android Models will prevent me from downloading the app?
            </h4>
            <p>
              The following Android Operating Systems are not compatible with
              the app:
              <ul>
                <li>Android 1.0 </li>
                <li>Android 1.1</li>
                <li>Android 1.5 Cupcake</li>
                <li>Android 1.6 Donut</li>
                <li>Android 2.0 - 2.1 Eclair</li>
                <li>Android 2.2 - 2.2.3 Froyo</li>
                <li>Android 2.3 - 2.3.7 Gingerbread</li>
                <li>Android 3.0 - 3.2.6 Honeycomb</li>
                <li>Android 4.0 - 4.0.4 Ice cream sandwich</li>
                <li>Android 4.1 - 4.3.1 Jelly Bean</li>
                <li>Android 4.4 - 4.4.4 KitKat</li>
              </ul>
            </p>
            <h4>What can I do when my app freezes or crashes?</h4>
            <p>
              Your phone may crash due to: Poor or unstable internet connection
              Lack of memory on the phone you are using
              <br />
              <br />
              The best way to “fix” your app once it has crashed is to “Quit”
              the app, and then open it again.
              <br />
              <br />
              On the android, you can do this by going to “Settings” and then
              select the app that keeps on crashing. Tap the app’s name and then
              press “Force Stop”. Now try opening the app again and see if it
              works better.
            </p>
            <h4>Why is there repeated login?</h4>
            <p>
              Make sure to download the latest version of the app to avoid
              repeated login.
            </p>
          </>
        ),
      },
      {
        q: "Other",
        a: (
          <>
            <h4>How do I delete my account?</h4>
            <p>
              You will need to get in touch with the admin of the app to delete
              your account or email hello@orangeandcrane.com.
            </p>
          </>
        ),
      },
    ],
  },
  {
    q: "Offerings / Online Donations",
    a: (
      <>
        <h4>How do I check if my offerings/donations went through?</h4>
        <p>
          An e-receipt will be sent to your email address after a successful
          offering is made. If you are signed in on the app, your latest
          offering will also reflect in the history of activities in your app.
        </p>
        <h4>How can I donate or make online transactions through the app? </h4>
        <p>
          You can make online transactions through different online facilities
          on the app like Gcash, Online Banks, Instapay and PesoNet.
        </p>
        <h4>What are the common problems and errors when paying?</h4>
        <p>
          If you encounter a problem while making an online transaction, kindly
          check the following scenarios and then try again:
          <ol>
            <li>Your bank is unwilling to accept the transaction</li>
            <li>
              The attempted transaction exceeds the withdrawal limit of the
              account
            </li>
            <li>The card is expired</li>
            <li>
              Incorrect details of the card were put in the fields when
              completing your donation.
            </li>
            <li>You were using unstable internet.</li>
            <li>
              Your bank might temporarily be offline, preventing the transaction
              to happen.
            </li>
          </ol>
          You may want to try again or another card to make a transaction.
        </p>
        <h4>Why are there failed transactions?</h4>
        <p>
          The most common reasons transactions fail are due to the following:
          <ol>
            <li>Your bank is unwilling to accept the transaction</li>
            <li>
              The attempted transaction exceeds the withdrawal limit of the
              account
            </li>
            <li>The card is expired</li>
            <li>
              Incorrect details of the card were put in the fields when
              completing your donation.
            </li>
            <li>You were using unstable internet.</li>
            <li>
              Your bank might temporarily be offline, preventing the transaction
              to happen.
            </li>
          </ol>
          You may want to try again or another card to make a transaction.
        </p>
        <h4>
          How do I get my credit card (One-Time PIN) OTP without close the app?
        </h4>
        <p>
          Your OTP (One-Time PIN) serves as an extra layer of security for your
          transactions and helps verify your identity and complete a
          transaction. The OTP is sent as a message from your bank separate to
          the app. To get your OTP, check your text messages and then key in the
          OTP in the app to complete your transaction.
        </p>
      </>
    ),
  },
  {
    q: "Prayer Requests",
    a: (
      <>
        <h4>How do I make a prayer request?</h4>
        <p>
          To make a “Prayer Request”, you will need to log into the app then
          select the “Prayer Request” tab. Complete the fields like name, time
          of mass, and reason, then press enter to proceed. Sending an offering
          with the prayer request is optional.
        </p>
      </>
    ),
  },
]

export default function Index() {
  return (
    <div className="bg-white h-screen">
      <Constraint child={<Template />} />
    </div>
  )
}

const defaultTitle = "Frequently Asked Questions"

const Template = () => {
  const [title, setTitle] = React.useState(defaultTitle)
  const [items, setItems] = React.useState(db)
  const [current, setCurrent] = React.useState(-1)

  const itemsRender = items.map((item, i) => (
    <div
      className="questionItem"
      key={i}
      onClick={() => {
        if (item.db) {
          setItems(item.db)
          setTitle(item.q)
        } else {
          setCurrent(i)
        }
      }}
    >
      <p>{item.q}</p>
      <img src={require("../assets/svg/icn-forward.svg")} alt="Select" />
    </div>
  ))

  let renderView = (
    <div key="questionList" className={`questionList`}>
      <h1>
        {title !== defaultTitle && (
          <img
            src={require("../assets/svg/icn-back.svg")}
            alt="Back"
            onClick={() => {
              setItems(db)
              setTitle(defaultTitle)
              setCurrent(-1)
            }}
          />
        )}
        <p>{title}</p>
      </h1>
      <p>Topics</p>
      {itemsRender}
    </div>
  )

  if (current > -1) {
    const currentItem = items[current]
    renderView = (
      <div key="questionList2" className={`questionList`}>
        <h1>
          <img
            src={require("../assets/svg/icn-back.svg")}
            alt="Back"
            onClick={() => setCurrent(-1)}
          />
          <p>{currentItem.q}</p>
        </h1>
        <div key="topicContent" className="topicContent">
          {currentItem.a}
        </div>
      </div>
    )
  }

  return <WideWrapper child={renderView} />
}
